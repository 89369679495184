import brandWrapper from 'api/direct-mail/utils/backend-wrappers/brandWrapper';
import lmpbpBrandWrapper from 'api/lmpbp/utils/backend-wrappers/brandWrapper';
import jwtDecode from 'jwt-decode';

import {
    GET_BRAND_INFO,
    GET_LOCK_DATE_REMINDER_LOADNING
} from './types';

import userSession from 'utils/userSession';
import { notifyGetBrandInfoError, notifyUpdateLocakDateReminderError, notifyUpdateLocakDateReminderSuccess} from './notificationActions';
import { getBrand } from 'api/direct-mail/brand';
import { getBrand as getBrandLmpbp } from 'api/lmpbp/brand';
import { updateBrandInAuthorizeApi } from 'api/lmpbp/brand';
import { updateLockDateReminderDm as updateLockDateReminderDmAction } from 'api/direct-mail/brand';

export const getBrandInfo = brandId => async dispatch => {
    dispatch(notifyGetBrandInfoError({ display: false }));

    let brandErr = undefined;
    let brandRes = undefined;

    if(userSession.arrivedAtLmpbp()) {
        const [err, res] = await getBrandLmpbp({ brandId });
        brandRes = res ? lmpbpBrandWrapper(res) : null;
        if(res){
            sessionStorage.setItem("toggle_lock_date_reminder", res?.toggle_lock_date_reminder)
        }
        brandErr = err;
    } else {
        const [err, res] = await getBrand({ brandId });
        brandRes = res ? brandWrapper(res) : null;
        if(res){
            sessionStorage.setItem("toggle_lock_date_reminder_dm", res?.toggle_lock_date_reminder);
        }
        brandErr = err;
    }

    if(brandErr) {
        dispatch(notifyGetBrandInfoError({ serverError: brandErr }))
    }

    return dispatch({
        type: GET_BRAND_INFO,
        payload: brandRes
    });
}
export const updateLockDateReminderDm = (toggle_lock_date_reminder) => async (dispatch) => {
    dispatch(notifyUpdateLocakDateReminderError({ display: false }));
    dispatch({
        type: GET_LOCK_DATE_REMINDER_LOADNING,
        payload: true
    });

    const [lockDateErr] = await updateLockDateReminderDmAction({
        toggle_lock_date_reminder:toggle_lock_date_reminder
    });

    if(lockDateErr) {
        dispatch(notifyUpdateLocakDateReminderError({ serverError: lockDateErr }));
        setTimeout(function() {
            dispatch(notifyUpdateLocakDateReminderError({display:false}));
        }, 2000);
    }
    dispatch({
        type: GET_LOCK_DATE_REMINDER_LOADNING,
        payload: false
    });

    dispatch(notifyUpdateLocakDateReminderSuccess());
    setTimeout(function() {
        dispatch(notifyUpdateLocakDateReminderSuccess({display:false}));
    }, 2000);
  };
  export const updateBrandInAuthorize =  ({
    BrandID = null, 
}) => async dispatch => {
    dispatch(notifyGetBrandInfoError({ display: false }));
    let brandErr = undefined;
        const [err, res] = await updateBrandInAuthorizeApi({ BrandID });
        if(res){
            userSession.set({isBrandChanged:true});
            const decodedToken = jwtDecode(res.token);
            userSession.set(decodedToken);
            userSession.setLmpbpToken(res.token);
            window.location.reload(false); 
        }
        brandErr = err;
    if(brandErr) {
        dispatch(notifyGetBrandInfoError({ serverError: brandErr }))
    }
}